import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import Card from "~/components/styled/card";
import Image from "~/components/image";
import Moment from "react-moment";
import ReactMarkdown from "react-markdown";
//import CardSlider from "~/components/styled/CardSlider";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { formatPrice } from "~/helpers/currency-formatter";

const ArticleCarousel = ({ articles, gridCols }) => {
	const CustomPrevArrow = ({ onClick }) => {
		return (
			<button
				className="absolute -left-6 z-10 p-1 text-black   rounded-full shadow-lg top-1/2 -translate-y-1/2"
				onClick={onClick}
			>
				◀
			</button>
		);
	};

	const CustomNextArrow = ({ onClick }) => {
		return (
			<button
				className="absolute -right-6 z-10 p-1 text-black   rounded-full shadow-lg top-1/2 -translate-y-1/2 "
				onClick={onClick}
			>
				▶
			</button>
		);
	};
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		arrows: true,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		slidesToShow: 3.25,
		slidesToScroll: 3,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2.25,
					slidesToScroll: 2,
					arrows: false,
					infinite: false,
					dots: true,
					initialSlide: 0,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1.25,
					slidesToScroll: 1,
					arrows: false,
					infinite: false,
					dots: false,
					initialSlide: 0,
				},
			},
		],
	};
	return (
		<div className="">
			<Slider {...settings}>
				{articles.map((article) => {
					return (
						<div className="relative pr-4 pl-1 mb-4">
							<Card key={article.id}>
								<Link to={`/blog/${article.slug}`} key={article.id}>
									<Image
										alt="Article Image"
										className="rounded-t-md transform border-gray-200	 border-b"
										loading="lazy"
										image={article.image}
									/>
									<div className="px-4 py-2">
										<div className="mb-2">
											<p className="font-medium text-sm hover:text-primary">
												{article.title}
											</p>
										</div>
										<div className="">
											<p className="text-xs text-gray-500 capitalize">
												last updated:{" "}
												<Moment format="MMM Do YYYY">{article.updated_at}</Moment>
											</p>
											{/* <p className="text-xs text-gray-500 capitalize">
												{article.author.name}
											</p> */}
										</div>
									</div>
								</Link>
							</Card>
						</div>
					);
				})}
			</Slider>
		</div>
	);
};

ArticleCarousel.propTypes = {
	articles: PropTypes.array,
	gridCols: PropTypes.string,
};

ArticleCarousel.defaultProps = {
	gridCols: "grid-cols-1 md:grid-cols-2 lg:grid-cols-3",
};

export default ArticleCarousel;
