import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { CheckIcon } from "@heroicons/react/outline";

const Hero = () => {
	const findTripUTM = new URLSearchParams({
		utm_source: "hero",
		utm_medium: "cta-button",
		utm_campaign: "find-trip",
	}).toString();

	const findTripWithUTM = `/tours/?${findTripUTM}`;

	const designTripUTM = new URLSearchParams({
		utm_source: "hero",
		utm_medium: "cta-button",
		utm_campaign: "design-my-trip",
	}).toString();

	const designTripWithUTM = `/design-trip/?${designTripUTM}`;

	return (
		<section className="relative bg-white border-b-0 border-gray-900 overflow-hidden">
			<div className="flex flex-col lg:flex-row items-center">
				{/* Left Half */}
				<div className="lg:w-1/2 py-8 px-8 lg:px-0 lg:pr-20 text-center lg:text-left">
					<div className="flex flex-col items-center lg:items-start">
						{/* <h1 className="text-xl text-black font-semibold sm:text-3xl md:text-3xl lg:text-3xl xl:text-4xl">
							Over 100 thoughtfully crafted cycling experiences spanning 16
							breathtaking destinations
						</h1>
						<p className="text-gray-600 my-5">
							Designed for those who love to stay active and curious while traveling.
						</p> */}
						<h1 className="text-4xl uppercase font-bold tracking-tight text-black sm:text-6xl">
							Your People.
							<br /> Your Ride.
						</h1>
						<p className="mt-5 text-lg font-normal sm:text-xl my-10  ">
							Boutique and personalized
							<span className=" "> cycling & e-bike tours</span> for every journey.
						</p>
						<div className="mb-10 ">
							<CheckIcon className="h-6 w-6 bg-primary2 text-white rounded-full p-1 inline-flex mr-2" />
							<span className="text-base text-primary2 font-medium ">
								E-bikes Available
							</span>
						</div>
						{/* <div className="mb-10 ">
							<CheckIcon className="h-6 w-6 bg-primary2 text-white rounded-full p-1 inline-flex mr-2" />
							<span className="text-base text-primary2 font-medium ">
								E-bikes Available
							</span>
						</div> */}
						<div className="flex flex-col w-full md:w-auto md:flex-row  md:space-x-4">
							<Link
								to={findTripWithUTM}
								className="bg-primary capitalize tracking-wide font-semibold text-white mb-4 md:mb-0 px-7 py-3 md:py-4 md:px-12 lg:px-8 xl:px-12 rounded-full"
							>
								Find trip
							</Link>
							<Link
								to={designTripWithUTM}
								className="bg-white capitalize border font-semibold tracking-wide  border-primary text-primary px-4 py-3 md:py-4 md:px-6 rounded-full"
							>
								Design private trip
							</Link>
						</div>
					</div>
				</div>

				{/* Right Half */}
				<div className="lg:w-1/2 mt-10 lg:mt-0 relative">
					{/* <p className="absolute text-lg sm:text-xl xl:text-2xl text-white font-semibold top-4 left-4 sm:top-8 sm:left-8 z-10">
						100+ immersive bike tours. <br />
						20+ destinations. <br />
						5000+ happy cyclists.
					</p> */}
					<StaticImage
						className="rounded-lg shadow-md transform border border-gray-200"
						src="../images/rajasthan-bike-tour-24.jpeg"
						alt="Cycling Rajasthan"
						//layout="FULL_WIDTH"
						aspectRatio="1.2"
						loading="lazy"
					/>
				</div>
			</div>
		</section>
	);
};

export default Hero;
